/* eslint-disable */

<template>
  <div id="Lgx245739812367548921">
    <vue-sortable class="flex gap-x-4" :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-col" style="width: 12%">
        <div class="bg-dark main-border rounded-lg">
          <vue-draggable-container id="wordBank" class="wordBank">
            <img
              class="dragBlockImage answerImage"
              v-for="choice in choices"
              :item-id="choice.id"
              :src="choice.image"
              width="100px"
              quality="100"
              style="min-height: 100px !important"
            />
          </vue-draggable-container>
        </div>

        <div
          class="flex items-center justify-center gap-x-2 bg-dark rounded-lg main-border p-2 mt-4 cursor-pointer text-xl"
          @click="helpActive = !helpActive"
        >
          <font-awesome-icon icon="fa-regular fa-circle-question" class="h-5 w-5" />
          <p class="flex items-center">Sim Help</p>
        </div>
      </div>

      <div
        class="vx-col w-full p-5 pb-0 bg-dark main-border mb-5 rounded-lg"
        style="width: 88%"
        :class="{ 'cursor-drag': draggingCard === true }"
      >
        <div class="flex justify-between pb-4 items-center">
          <p class="flex items-center justify-center text-2xl">Page {{ currentPage }}/3</p>

          <div class="flex items-center gap-x-4">
            <vs-button
              class="w-fit font-bold"
              icon="icon-check-circle"
              icon-pack="feather"
              color="success"
              type="border"
              @click="gradePage"
              v-if="!pageGraded"
            >
              Grade Page
            </vs-button>

            <vs-button
              class="w-fit font-bold"
              icon="icon-check-circle"
              icon-pack="feather"
              color="primary"
              type="border"
              @click="nextPage"
              v-if="pageGraded && currentPage !== 3"
            >
              Next Page
            </vs-button>
          </div>
        </div>

        <div
          class="vx-col flex mb-5 w-full bg-light p-4 px-3 rounded-lg main-border"
          style="min-height: 130px"
          v-for="(scenario, index) in paginatedScenarios"
          :key="scenario.id"
          @click="click_remove"
        >
          <div class="flex flex-row w-full gap-x-3">
            <div class="flex" style="min-width: 100px">
              <vue-draggable-container
                class="answerStandard dropzone dropzoneStandard answer-bg answerImages"
                :id="'input' + index"
                :a="scenario.answer"
              />
            </div>

            <div class="flex flex-col w-full">
              <div class="flex flex-row justify-between">
                <div class="flex items-center gap-x-4 w-full justify-between">
                  <h2 class="flex items-center">{{ scenario.name }}</h2>
                </div>
              </div>
              <h4 class="mt-3 text-dark font-light">{{ scenario.desc }}</h4>
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>

    <movable-popup
      refVal="movableHelpPopup"
      title="Simulation Help - Drag Here To Move"
      :active="helpActive"
      v-on:close="helpActive = false"
    >
      <template v-slot:content>
        <slide-x-left-transition>
          <div class="overflow-hidden">
            <vs-tabs>
              <vs-tab label="Virtual Machine" icon-pack="feather" icon="icon-server">
                <div class="tab-text">
                  <h2 class="mt-1">What is a Virtual Machine?</h2>
                  <p class="text-dark mt-2">
                    A Virtual Machine (VM) is a software emulation of a physical computer that runs an operating system and applications. It
                    operates in an isolated environment, allowing multiple VMs to run on a single physical machine. Each VM includes its own
                    virtual hardware, such as CPU, memory, storage, and network interfaces.
                  </p>

                  <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
                  <span class="text-dark"
                    >Imagine a magical playroom where you and your friends can build your own dream LEGO worlds. Each friend gets their own
                    table with all the LEGO bricks they need, and they can create whatever they want. Even if you're all playing in the same
                    room (computer), you have your own space and don't mix up your LEGO creations. That separate space for each friend is
                    like having a virtual machine, where everyone gets their own special area to play without interfering with others.</span
                  >

                  <h4 class="mt-4 pt-4 mb-2 main-top-border">Buzzwords</h4>
                  <div class="grid grid-cols-4 gap-3">
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Hypervisor</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Emulation</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Isolation</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Full OS</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Resource Overhead</span>
                  </div>
                </div>
              </vs-tab>

              <vs-tab label="Container" icon-pack="feather" icon="icon-package">
                <div class="tab-text">
                  <h2 class="mt-1">What is a Container?</h2>
                  <p class="text-dark mt-2">
                    A Container is a lightweight, standalone, and executable software package that includes everything needed to run an
                    application, including the code, runtime, libraries, and system tools. Containers share the host operating system's
                    kernel but operate in isolated user spaces, providing a consistent and portable environment.
                  </p>

                  <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
                  <span class="text-dark"
                    >Picture a magical lunchbox that holds a complete picnic for your teddy bear. In the lunchbox, you have a sandwich, some
                    fruit, and a little juice box—all neatly packed. Now, if you want to share your picnic with your friend, you can give
                    them the same magical lunchbox. Inside the lunchbox, everything is organized, and you can carry it anywhere you want.
                    The magical lunchbox is like a container, holding everything your teddy bear needs for a delicious picnic, and you can
                    easily share it with others.</span
                  >

                  <h4 class="mt-4 pt-4 mb-2 main-top-border">Buzzwords</h4>
                  <div class="grid grid-cols-4 gap-3">
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Docker</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Microservices</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Portability</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Lightweight</span>
                    <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Isolation</span>
                  </div>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </slide-x-left-transition>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      clonedNode: null,
      currentSourceContainerId: null,
      originalNodeId: null,
      origSourceContainer: null,

      helpActive: false,

      currentPage: 1,
      scenariosPerPage: 3,
      pageGraded: false,
      totalScore: 0,

      startIndex: 0,
      endIndex: 0,

      quizComplete: false,

      scenarios: [
        {
          id: 1,
          answer: 2,
          name: 'Development Environment Isolation',
          desc: 'A software development team is working on a project that requires different versions of programming languages and libraries. Each developer needs an isolated environment to avoid conflicts. What would be the best choice: virtual machine, container, or wrapper?',
        },
        {
          id: 2,
          answer: 2,
          name: 'Enterprise Data Center Optimization',
          desc: 'An enterprise operates a diverse data center with web servers, database servers, and application servers. The organization aims to optimize resource utilization, enhance scalability, and ensure security boundaries between workloads. The challenge is to efficiently allocate resources and maintain a secure environment. What would be the ideal choice?',
        },
        {
          id: 3,
          answer: 2,
          name: 'R&D Environment Flexibility',
          desc: 'An R&D team is conducting experiments with diverse operating systems and software configurations. They require isolated environments for each project to streamline testing without interference. The challenge is to facilitate innovation, maintain flexibility in software versions, and ensure project independence. What would be the ideal choice?',
        },
        {
          id: 4,
          answer: 2,
          name: 'Running Legacy Software',
          desc: 'An organization has legacy software that requires an outdated operating system and specific configurations. They want to isolate this application to maintain security and stability. What would be the ideal choice: virtual machine, container, or wrapper?',
        },
        {
          id: 5,
          answer: 1,
          name: 'Lightweight Application Deployment',
          desc: 'A small web application needs to be deployed quickly and efficiently. The goal is to have a lightweight solution that can run on various servers without a lot of overhead. Which option is most suitable: virtual machine, container, or wrapper?',
        },

        {
          id: 6,
          answer: 1,
          name: 'Distributing a Software Demo',
          desc: 'A software company wants to distribute a demo of their application that includes all the necessary components for a user to try it out. They aim for a solution that is easy for users to run on their own machines. What option is most appropriate: virtual machine, container, or wrapper?',
        },
        {
          id: 7,
          answer: 1,
          name: 'Running Multiple Instances on a Server',
          desc: 'A server administrator needs to run multiple instances of a database server on the same physical machine to handle different projects. They want an efficient solution that allows for resource optimization. What should they choose: virtual machine, container, or wrapper?',
        },
        {
          id: 8,
          answer: 1,
          name: 'Ensuring Application Isolation in Shared Environment',
          desc: "In a shared hosting environment, different users are running their web applications. There is a need to ensure that each user's application is isolated from others to maintain security. What option is the most suitable: virtual machine, container, or wrapper?",
        },
        {
          id: 9,
          answer: 1,
          name: 'Rapid Scaling of Microservices',
          desc: 'A cloud-based application consists of microservices that need to scale rapidly based on demand. The deployment solution should allow for quick scaling without consuming excessive resources. What choice is most appropriate: virtual machine, container, or wrapper?',
        },
      ],

      choices: [
        {
          id: '1',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/243816597420835671/container.png',
        },
        {
          id: '2',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/243816597420835671/virtual-machine.png',
        },
      ],

      options: {
        draggable: '.dragBlockImage',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  computed: {
    shuffledScenarios() {
      return shuffle(this.scenarios);
    },
    paginatedScenarios() {
      this.startIndex = (this.currentPage - 1) * this.scenariosPerPage;
      this.endIndex = this.startIndex + this.scenariosPerPage;
      return this.shuffledScenarios.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    emitQuizCorrect() {
      this.quizComplete = true;
      return this.$emit('simulation_quiz_correct', this.quizData.questions);
    },
    startedDrag(x) {
      if (x.data.source.className.includes('stillBlockDataClassification')) {
      } else {
        x.data.source.className += ' currently-dragging-image';
        const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.draggingCard = true;
        x.data.originalSource.id = uuid;
        const originalNode = x.data.originalSource;
        this.originalNodeId = x.data.source.getAttribute('item-id');

        this.clonedNode = originalNode.cloneNode(true);
        this.clonedNode.classList.add('clonedElement');
        this.clonedNode.id += '-cloned';
        originalNode.parentNode.insertBefore(this.clonedNode, originalNode.nextSibling);
      }
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      this.origSourceContainer = x.data.dragEvent.data.originalSource;
      if (overContainer.classList.contains('wordBank')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }
      }
      if (overContainer && overContainer.classList.contains('dropzone')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }

        this.currentSourceContainerId = this.origSourceContainer.id;

        setTimeout(() => {
          document.getElementById(`${this.currentSourceContainerId}-cloned`).style.display = 'inline-flex';
          document.getElementById(this.currentSourceContainerId).classList.remove('dragBlockImage');
          document.getElementById(this.currentSourceContainerId).classList.add('stillBlockDataClassification');
        }, 2);

        return;
      }
      document.getElementById(`${this.origSourceContainer.id}-cloned`).outerHTML = '';
    },
    gradePage() {
      let invalidAnswer = false;
      const inputChildren = [];
      const inputs = [];

      for (let i = 0; i <= 2; i++) {
        const inputChild = document.getElementById(`input${i}`);
        inputs.push(inputChild);

        if (inputChild && inputChild.querySelector('img')) {
          inputChildren.push(inputChild.querySelector('img'));
        }
      }

      for (const [index, input] of inputs.entries()) {
        if (inputChildren[index]) {
          const inputChildID = inputChildren[index].getAttribute('item-id');
          const inputAnswer = inputs[index].getAttribute('a');

          if (inputAnswer === inputChildID) {
            inputs[index].style.borderColor = '#28C76F';
            inputs[index].style.backgroundColor = '#28C76F';
          } else {
            inputs[index].style.borderColor = '#fd4445';
            inputs[index].style.backgroundColor = '#fd4445';
            invalidAnswer = true;
          }
        } else {
          inputs[index].style.borderColor = '#fd4445';
          inputs[index].style.backgroundColor = '#fd4445';
          invalidAnswer = true;
        }
      }

      if (!invalidAnswer) {
        this.pageGraded = true;
        this.totalScore = this.totalScore + 3;
        this.$emit('simulation_update_score', this.totalScore);
      }
    },
    nextPage() {
      this.pageGraded = false;
      this.currentPage++;
    },
    click_remove(x) {
      const target = x.target;
      const targetID = target.getAttribute('item-id');
      if (target.classList.contains('stillBlockDataClassification')) {
        target.outerHTML = '';
      }
    },
    markSimulation() {
      if (this.totalScore !== this.dataLocal.simulation.totalScore) {
        const message = 'Please complete and grade all pages before submitting!';
        this.$emit('simulation_not_complete', this.totalScore, message);
      }
    },
  },
  mounted() {
    this.dataLocal.options.showMarkButton = false;
    this.$emit('simulation_update_options', this.dataLocal);
  },
  components: {
    draggable,
    Prism,
    shuffle,
    MovablePopup,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.wordBank {
  min-height: unset !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 1.2rem 0rem 1.2rem 0rem;
}

.stillBlockDataClassification {
  word-break: break-all;
  background-color: rgba(var(--vs-primary), 1);
  display: inline-flex;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800 !important;
  cursor: url('~@/assets/images/cursor/trash-cursor.png'), auto !important;
  background-color: #011d56 !important;
  border: 1px solid #0053ff !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
}

.answerImages {
  height: 102px;
  width: fit-content;
  min-width: 100px;
  max-width: 100px;
  display: flex;
  flex-direction: row;
}
</style>

<style lang="scss">
#Lgx245739812367548921 {
  .draggable-source--is-dragging {
    width: 100px !important;
  }

  .vs-tabs--content {
    padding: 0px !important;
    margin-top: 1.5rem;
  }
}
</style>
